<template>
  <v-dialog
    :value="showModal"
    width="526"
    @click:outside="$emit('closeModal', false)"
  >
    <v-card class="modal-container">
      <v-btn
        icon
        class="modal__close"
        @click="$emit('closeModal', false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <p class="modal__title text-center">Вы уверены, что хотите выйти?</p>
      <p class="modal__sub-title text-center">
        Все внесенные данные будут удалены
      </p>

      <div class="card-actions d-flex justify-center">
        <v-btn
          color="#212121"
          light
          outlined
          class="mt-2 mr-2 modal__btn modal__btn--auto"
          x-large
          @click="$emit('closeModal', false)"
        >
          Отмена
        </v-btn>
        <v-btn
          color="#212121"
          dark
          class="mt-2 modal__btn modal__btn--auto"
          x-large
          @click="$router.push('/roles')"
        >
          Выйти
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import notification from '@/mixins/notification';

export default {
  name: 'ApproveCancelModal',
  mixins: [notification],
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
</style>
