<template>
  <v-dialog
    :value="showModal"
    width="526"
    @click:outside="$emit('closeModal', false)"
  >
    <v-card class="modal-container">
      <v-btn
        icon
        class="modal__close"
        @click="$emit('closeModal', false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <p class="modal__title text-center">Ошибка</p>
      <p class="modal__sub-title text-center" v-if="isList">
        Чтобы удалить роль «{{ role ? role.name : '' }}»,
        перейдите на <a v-if="role" @click="openRole">страницу редактирования роли</a>
        и удалите сотрудников из поля «Сотрудники с этой ролью»
      </p>
      <p class="modal__sub-title text-center" v-else>
        Чтобы удалить роль «{{ role ? role.name : '' }}» удалите сотрудников из поля «Сотрудники с этой ролью»
      </p>
      <p class="modal__desc text-center">
        Количество пользователей, у которых подключена эта роль должно быть 0.
      </p>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import notification from '@/mixins/notification';

export default {
  name: 'ApproveDeleteModal',
  mixins: [notification],
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    isList: {
      type: Boolean,
      default: true,
    },
    role: {
      type: Object,
    },
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions('roles', ['getRole']),

    async openRole() {
      try {
        await this.getRole(this.role.id);
        this.$router.push(`/roles/edit/${this.role.id}`);
      } catch (error) {
        //
      }
    },
  },
};
</script>

<style scoped>
</style>
