<template>
  <div class="roles">
    <div class="roles-header">
      <div class="roles-header-title">
        <div
          class="roles-header-title__back"
          @click="$router.push('/roles')"
        >
          <v-icon color="#D6D6D6">mdi-chevron-left</v-icon>
        </div>
        <div class="roles-header-title__heading">Редактирование роли</div>
      </div>

      <div class="roles-header-add">
        <v-btn
          color="#FF5252"
          dark
          outlined
          class="radius-root mr-1 roles-header-add__short"
          x-large
          @click="deleteRole"
        >
          <v-icon color="#FF5252">mdi-delete</v-icon>
        </v-btn>
        <v-btn
          color="black"
          dark
          outlined
          class="radius-root mr-1"
          x-large
          @click="cancel"
        >
          {{ hasChanges ? 'Отменить изменения' : 'Отменить' }}
        </v-btn>
        <v-btn
          color="black"
          dark
          class="radius-root"
          x-large
          :disabled="!hasChanges"
          @click="save"
        >
          Сохранить
        </v-btn>
      </div>
    </div>
    <div class="roles-sections" v-if="role && role.id">
      <RoleCardMain :showNameError="showNameError" :roleEditData="role" />
      <RoleCardPermissions :roleEditData="role" />
    </div>
    <ApproveDeleteModal @closeModal="closeModals()" :isList="false" :role="role" :showModal="showApproveDeleteModal" />
    <ApproveCancelModal @closeModal="closeModals()" :showModal="showApproveCancelModal" />
    <DeleteErrorModal @closeModal="closeModals()" :isList="false" :role="role" :showModal="showDeleteErrorModal" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import notification from '@/mixins/notification';
import RoleCardMain from './components/RoleCardMain.vue';
import RoleCardPermissions from './components/RoleCardPermissions.vue';
import ApproveDeleteModal from './components/ApproveDeleteModal.vue';
import ApproveCancelModal from './components/ApproveCancelModal.vue';
import DeleteErrorModal from './components/DeleteErrorModal.vue';

export default {
  name: 'RoleEdit',
  components: {
    RoleCardMain,
    RoleCardPermissions,
    ApproveDeleteModal,
    DeleteErrorModal,
    ApproveCancelModal,
  },
  mixins: [notification],

  data() {
    return {
      hasChanges: false,
      showApproveDeleteModal: false,
      showApproveCancelModal: false,
      showDeleteErrorModal: false,
      showNameError: false,
    };
  },

  computed: {
    ...mapState('roles', ['role', 'roleTempMain', 'roleTempPermissions']),
  },

  async created() {
    if (!this.role.id) await this.getRole(this.$route.params.id);
  },

  methods: {
    ...mapActions('roles', ['getRole', 'editRole']),
    ...mapMutations('roles', ['setRoleTempMain', 'setRoleTempPermissions']),

    deleteRole() {
      if (this.role.emp_count > 0) {
        this.showDeleteErrorModal = true;
      } else {
        this.showApproveDeleteModal = true;
      }
    },

    closeModals() {
      this.showApproveDeleteModal = false;
      this.showApproveCancelModal = false;
      this.showDeleteErrorModal = false;
    },

    valid(data) {
      if (data.name === '' || data.length < 1) {
        this.showNameError = true;
        return false;
      }
      return true;
    },

    cancel() {
      if (this.hasChanges) {
        this.showApproveCancelModal = true;
      } else {
        this.$router.push('/roles');
      }
    },

    async save() {
      this.showNameError = false;
      const data = {
        ...this.roleTempMain,
        ...this.roleTempPermissions,
      };
      data.users = data.users.map((m) => m.id);

      if (this.valid(data)) {
        try {
          const res = await this.editRole(data);
          if (!this.validateStatusCode(res)) {
            if (res.data.name) {
              this.showErrorNotification('Роль с таким названием уже существует');
              this.showNameError = true;
            } else {
              throw new Error('error');
            }
          } else {
            this.showSuccessNotification('Изменения успешно сохранены');
            this.$router.push('/roles');
          }
        } catch (error) {
          this.showErrorNotification('Произошла ошибка попробуйте снова');
        }
      } else {
        this.showErrorNotification('Заполните название роли');
        this.showNameError = true;
      }
    },
  },

  watch: {
    roleTempMain: {
      deep: true,
      handler(n, o) {
        if (o) this.hasChanges = true;
      },
    },
    roleTempPermissions: {
      deep: true,
      handler(n, o) {
        if (o) this.hasChanges = true;
      },
    },
  },

  destroyed() {
    this.setRoleTempMain(false);
    this.setRoleTempPermissions(false);
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables.scss";

</style>
