<template>
  <v-dialog
    :value="showModal"
    width="526"
    @click:outside="$emit('closeModal', false)"
  >
    <v-card class="modal-container">
      <v-btn
        icon
        class="modal__close"
        @click="$emit('closeModal', false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <p class="modal__title text-center">Удалить роль «{{ role ? role.name : '' }}»?</p>
      <p class="modal__sub-title text-center">
        Действие нельзя будет отменить
      </p>

      <div class="card-actions d-flex justify-center">
        <v-btn
          color="#212121"
          light
          outlined
          class="mt-2 mr-2 modal__btn modal__btn--auto"
          x-large
          @click="$emit('closeModal', false)"
        >
          Отмена
        </v-btn>
        <v-btn
          color="#FF5252"
          dark
          class="mt-2 modal__btn modal__btn--auto"
          x-large
          @click="initRoleDelete"
        >
          Удалить
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import notification from '@/mixins/notification';

export default {
  name: 'ApproveDeleteModal',
  mixins: [notification],
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    isList: {
      type: Boolean,
      default: true,
    },
    role: {
      type: Object,
    },
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions('roles', ['deleteRole']),

    async initRoleDelete() {
      try {
        const res = await this.deleteRole(this.role.id);
        if (!this.validateStatusCode(res)) {
          throw new Error('error');
        } else {
          this.showSuccessNotification(`Роль «${this.role.name}» успешно удалена`);
          this.$emit('closeModal', false);
          if (!this.isList) {
            this.$router.push('/roles');
          }
        }
      } catch (error) {
        this.showErrorNotification('При удалении роли произошла ошибка');
      }
    },
  },
};
</script>

<style scoped>
</style>
